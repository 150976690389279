import { Route, Routes } from "react-router-dom";
import Login from "./Auth/Login";
import SchoolCode from "./Auth/SchoolCode";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";

function App () {

  return (

    <Routes>

      <Route path="/" element={<Login/>}/>
      <Route path="/SchoolCode" element={<SchoolCode/>}/>
      <Route path="/Home/:schoolId" element={<Home/>}/>

      <Route path="/Profile/:schoolId/:studentId" element={<Profile/>}/>

    </Routes>

  )
}

export default App