import React, { useEffect, useState } from "react";
import { db } from '../Components/Firebase';
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import '../Styles/Pages/Profile.css';

function Profile() {
    const { studentId, schoolId } = useParams();
    const [studentData, setStudentData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [signature, setSignature] = useState(null);
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const sigCanvas = React.useRef({});
    const nav = useNavigate();
    const [language, setLanguage] = useState("hebrew");

    const translations = {
        hebrew: {
            title: "אישור היציאה",
            currentDateTime: "תאריך ושעה נוכחיים:",
            firstName: "שם פרטי:",
            lastName: "שם משפחה:",
            phoneNumber: "מספר טלפון:",
            responsibilityStatement: "אני לוקח על עצמי את האחריות להוציא מבית הספר את התלמיד",
            preview: "תצוגה מקדימה:",
            signButton: "לחתום",
            saveConfirmationButton: "שמירת אישור היציאה",
            errorMessage: "שגיאה במהלך שליפת נתוני התלמיד.",
            signatureErrorMessage: "אנא חתום לפני השמירה.",
            successMessage: "אישור היציאה נשמר בהצלחה.",
        },
        arabic: {
            title: "تأكيد الخروج",
            currentDateTime: "التاريخ والوقت الحالي:",
            firstName: "الاسم الأول:",
            lastName: "اسم العائلة:",
            phoneNumber: "رقم الهاتف:",
            responsibilityStatement: "أتحمل مسؤولية إخراج الطالب من المدرسة",
            preview: "المعاينة:",
            signButton: "التوقيع",
            saveConfirmationButton: "حفظ تأكيد الخروج",
            errorMessage: "حدث خطأ أثناء استرجاع بيانات الطالب.",
            signatureErrorMessage: "يرجى التوقيع قبل الحفظ.",
            successMessage: "تم حفظ تأكيد الخروج بنجاح.",
        }
    };

    useEffect(() => {
        const fetchStudentAndSchoolData = async () => {
            try {
                const studentDoc = await db.collection("Schools").doc(schoolId).collection("Students").doc(studentId).get();
                if (studentDoc.exists) {
                    setStudentData({ id: studentDoc.id, ...studentDoc.data() });
                } else {
                    setError(translations[language].errorMessage);
                }

                const schoolDoc = await db.collection("Schools").doc(schoolId).get();
                if (schoolDoc.exists) {
                    const schoolData = schoolDoc.data();
                    setSchoolData(schoolData);
                    setLanguage(schoolData?.tabletSettings?.language?.toLowerCase() || "hebrew");
                } else {
                    setError(translations[language].errorMessage);
                }
            } catch (err) {
                setError(translations[language].errorMessage);
            } finally {
                setLoading(false);
            }
        };

        fetchStudentAndSchoolData();

        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-US');
        const formattedTime = now.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });

        setCurrentDate(formattedDate);
        setCurrentTime(formattedTime);
    }, [studentId, schoolId, language]);

    const saveSignature = () => {
        setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL());
    };

    const saveExitConfirmation = async () => {
        if (!signature) {
            alert(translations[language].signatureErrorMessage);
            return;
        }
    
        const formatPhoneNumber = (phone) => {
            if (!phone) return "לא זמין";
            phone = phone.toString();
            phone = phone.replace(/\D/g, '');
            if (phone.startsWith("0")) {
                return "972" + phone.slice(1);
            }
            return phone;
        };
    
        const firstPhoneNumber = studentData.phoneNumbers?.length 
            ? formatPhoneNumber(studentData.phoneNumbers[0]) 
            : "לא זמין";
    
        const responsibilityStatement = `${translations[language].responsibilityStatement} ${studentData.firstName || 'שם פרטי'} ${studentData.lastName || 'שם משפחה'} ${translations[language].currentDateTime}: ${currentDate} ${currentTime}.`;
    
        const confirmationData = {
            studentId,
            schoolId,
            studentName: `${studentData?.firstName || ''} ${studentData?.lastName || ''}`,
            phoneNumber: firstPhoneNumber,
            preM: studentData.preM,
            messagesLang: schoolData.MessagesLang || '',
            date: currentDate,
            time: currentTime,
            signature,
            responsibilityStatement,
        };
    
        try {
            await db.collection("Schools")
                .doc(schoolId)
                .collection("ExitPre")
                .add(confirmationData);

            await db.collection("Schools")
                .doc(schoolId)
                .collection("Students")
                .doc(studentId)
                .update({ status: "notAvailable" });
    
            alert(translations[language].successMessage);
            nav(-1);
        } catch (error) {
            alert("שגיאה במהלך שמירת האישור.");
        }
    };
    
    

    if (loading) {
        return <p>טוען...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <React.Fragment>
            {studentData && (
                <div className="profile-container" dir="rtl">
                    <h2>{translations[language].title}</h2>
                    <p>{translations[language].currentDateTime} {currentDate} {currentTime}</p>
                    <p>{translations[language].firstName} {studentData.firstName || 'לא זמין'}</p>
                    <p>{translations[language].lastName} {studentData.lastName || 'לא זמין'}</p>
                    <p>{translations[language].phoneNumber} {studentData.phoneNumbers?.join(", ") || 'לא זמינים'}</p>
                    <div className="responsibility-statement">
                        <p>
                            {`${translations[language].responsibilityStatement} ${studentData.firstName || 'שם פרטי'} ${studentData.lastName || 'שם משפחה'} ${translations[language].currentDateTime}: ${currentDate} ${currentTime}.`}
                        </p>
                    </div>
                    <div className="signature-pad">
                        <SignatureCanvas
                            ref={sigCanvas}
                            penColor='black'
                            canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
                        />
                        {signature && (
                            <div className="signature-preview">
                                <h3>{translations[language].preview}</h3>
                                <img src={signature} alt="חתימה" />
                            </div>
                        )}
                        <button onClick={saveSignature} className="save-signature-button">{translations[language].signButton}</button>
                        <button onClick={saveExitConfirmation} className="save-signature-button">{translations[language].saveConfirmationButton}</button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Profile;
