import React, { useState, useEffect } from "react";
import { db } from '../Components/Firebase';
import { useParams } from "react-router-dom";
import '../Styles/Pages/Home.css';
import { useNavigate } from "react-router-dom";
import Vec1 from '../Images/Vec1.jpg';
import Logo from '../Images/Logo.png';

function Home() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [studentsData, setStudentsData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const nav = useNavigate();
  const { schoolId } = useParams();
  const [language, setLanguage] = useState("hebrew");

  const translations = {
    hebrew: {
      title: "אישור היציאה",
      phoneNumberPlaceholder: "מספר טלפון",
      searchButton: "חפש",
      searchingButton: "מחפש...",
      errorMessage: "לא נמצאו תלמידים עם מספר הטלפון שסיפקת.",
      generalErrorMessage: "אירעה שגיאה במהלך החיפוש.",
      studentDetailsHeader: "פרטי תלמידים:",
      firstNameLabel: "שם פרטי:",
      phoneNumberLabel: "מספר טלפון:",
      confirmationButton: "אישור היציאה",
    },
    arabic: {
      title: "تأكيد الخروج",
      phoneNumberPlaceholder: "رقم الهاتف",
      searchButton: "ابحث",
      searchingButton: "جاري البحث...",
      errorMessage: "لم يتم العثور على طلاب برقم الهاتف الذي قدمته.",
      generalErrorMessage: "حدث خطأ أثناء البحث.",
      studentDetailsHeader: "تفاصيل الطالب:",
      firstNameLabel: "الاسم الأول:",
      phoneNumberLabel: "رقم الهاتف:",
      confirmationButton: "تأكيد الخروج",
    }
  };

  useEffect(() => {
    const fetchLanguageSetting = async () => {
      try {
        const schoolDoc = await db.collection("Schools").doc(schoolId).get();

        if (schoolDoc.exists) {
          const schoolData = schoolDoc.data();
          const tabletSettings = schoolData.tabletSettings || {};

          if (tabletSettings.language) {
            setLanguage(tabletSettings.language.toLowerCase());
          } else {
            setLanguage("hebrew");
          }

          if (tabletSettings.notes) {
            setNotes(tabletSettings.notes);
          }
        }
      } catch (err) {
        setLanguage("hebrew");
      }
    };

    fetchLanguageSetting();
  }, [schoolId]);

  // Real-time listener for Notes and Language updates
  useEffect(() => {
    const unsubscribe = db
      .collection("Schools")
      .doc(schoolId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const schoolData = doc.data();
          const tabletSettings = schoolData.tabletSettings || {};

          if (tabletSettings.language) {
            setLanguage(tabletSettings.language.toLowerCase());
          } else {
            setLanguage("hebrew");
          }

          if (tabletSettings.notes) {
            setNotes(tabletSettings.notes);
          }
          if (!tabletSettings.notes) {
            setNotes('');
          }
        }
      });

    // Cleanup on unmount
    return () => unsubscribe();
  }, [schoolId]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setError('');
    setStudentsData([]);
    setLoading(true);

    try {
      const querySnapshot = await db
        .collection("Schools")
        .doc(schoolId)
        .collection("Students")
        .where("phoneNumbers", "array-contains", phoneNumber)
        .get();

      setLoading(false);

      if (!querySnapshot.empty) {
        const students = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setStudentsData(students);
      } else {
        setError(translations[language].errorMessage);
      }
    } catch (err) {
      setLoading(false);
      setError(translations[language].generalErrorMessage);
    }
  };

  return (
    <React.Fragment>
      <div className="MainTap">
        <img
          src={Vec1}
          alt="Vec1"
          onContextMenu={(e) => { e.preventDefault() }}
          onDragStart={(e) => { e.preventDefault() }}
        />
        <div className="home-container" dir="rtl">
          {notes && <div className="notes-container">{notes}</div>}

          <h2 style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            {translations[language].title}
            <img
              style={{ width: '100px' }}
              src={Logo}
              onContextMenu={(e) => { e.preventDefault() }}
              onDragStart={(e) => { e.preventDefault() }}
            />
          </h2>
          <form onSubmit={handleSearch}>
            <div>
              <input
                type="number"
                id="phoneNumber"
                value={phoneNumber}
                placeholder={translations[language].phoneNumberPlaceholder}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                pattern="[0-9]{10}"
                title="נא להזין מספר טלפון באורך 10 ספרות"
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? translations[language].searchingButton : translations[language].searchButton}
            </button>
          </form>

          {error && <p className="error-message">{error}</p>}
          {studentsData.length > 0 && (
            <div>
              <h3>{translations[language].studentDetailsHeader}</h3>
              {studentsData.map(student => (
                <div key={student.id}>
                  <p>{translations[language].firstNameLabel} {student.firstName + ' ' + student.lastName || 'לא זמין'}</p>
                  <p>{translations[language].phoneNumberLabel} {student.phoneNumbers.join(", ") || 'לא זמין'}</p>
                  <button onClick={() => nav(`/Profile/${schoolId}/${student.id}`)}>
                    {translations[language].confirmationButton}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
